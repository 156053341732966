@import '../../Styles/shared.scss';

.selection-option-outer-container {
  padding: 4px 12px;
  cursor: pointer;
  border-top: $border-white;
  border-bottom: $border-white;
  position: relative;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &:hover {
    color: $darkest !important;
    background: $prettymuchwhite;
    border-top: $border-offwhite;
    border-bottom: $border-offwhite;
  }

  .title {
    font-size: 12px;
    color: $darkest;
    font-weight: bold;
    text-transform: inherit;
    white-space: pre-line;
  }

  .subtitle {
    font-size: 10px;
    color: $medium;
    font-weight: normal;
    text-transform: normal;
    white-space: pre-line;
  }

  .icons {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    align-items: center;

    svg {
      width: 10px;
      height: 10px;
      padding: 6px;
      background-color: white;
      color: $dark;
      transition: opacity 0.2s ease-in-out;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}
