@import '../../Styles/shared.scss';

.presentation-builder-training-outer {
  width: 100%;

  .presentation-builder-content-title {
    font-family: $font-header;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .presentation-builder-training-inner {
    width: min(750px, 100%);
    @extend %flex-row;
    gap: 10px;
    flex-direction: column;

    .training-video-container {
      flex: 1;

      .saving-container {
        text-align: right;
        font-size: 0.5rem;
        color: $medium;
        @extend %label;

        span {
          margin-left: 5px;
        }
      }
    }

    .presentation-builder-content-container {
      @extend %flex-col;
      flex: 1;
      gap: 20px;

      .disclaimer {
        font-size: 0.7rem;
        color: $medium;
        font-weight: bold;
      }

      .presentation-builder-content-select {
        width: 100%;
        z-index: 20;
        font-size: 0.8rem;
      }
    }

    .training-video-container {
      // width: min(500px, 100%);
      width: 100%;
      background-color: white;
      overflow: hidden;

      iframe {
        width: 100%;
        aspect-ratio: 16/9;
        outline: none;
        border: none;
        border: $border-lightest;
        border-radius: 16px;
        overflow: hidden;
      }
    }
  }
}
