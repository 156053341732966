@import '../../Styles/shared.scss';

.help-modal-outer {
  .help-modal-inner {
    .help-modal-content {
      padding: 10px;
      width: 450px;

      .help-modal-header {
        font-family: $font-header;
        font-size: 2.4rem;
        margin-bottom: 20px;
      }

      .help-modal-subtitle {
        font-family: $font-body-new;
        font-size: 0.75rem;
        color: $light;

        span {
          @extend %btn-basic;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .help-modal-form {
        margin-bottom: 20px;

        input {
          padding: 8px 12px;
          border-radius: 5px;
          border: $border-lightest;
          font-family: $font-body-new;
          font-size: 0.75rem;
          color: $medium;
          width: 100%;
          margin-bottom: 10px;
        }

        textarea {
          padding: 8px 12px;
          border-radius: 5px;
          border: $border-lightest;
          font-family: $font-body-new;
          font-size: 0.75rem;
          color: $medium;
          width: 100%;
          height: 100px;
        }
      }

      .help-modal-submit {
        @extend %btn-basic;
        @extend %label;
        width: 100%;
        padding: 14px 16px;
        color: white;
        background-color: black;
        border: none;
        outline: none;
        border-radius: 6px;
        font-size: 0.7rem;
        text-align: center;
      }
    }
  }
}
