html {
  font-size: 18px;
  font-family: 'DM Sans', 'Karla';
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'DM Sans', 'Karla';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

a {
  margin: 0;
  color: unset;
}
