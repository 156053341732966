@import '../../Styles/shared.scss';

.landing-outer {
  .landing-inner {
    .landing-buttons {
      z-index: 2;
      position: fixed;
      top: 20px;
      right: 40px;
      @extend %flex-row;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;

      > div {
        @extend %btn-basic;
        @extend %label;
        padding: 8px 12px;
        border-radius: 5px;
        font-family: $font-body-new;
        font-size: 0.7rem;
        font-weight: bold;
        color: $offwhite;
        background-color: $dark;

        &.get-started-button {
          background-color: $primary;
          color: $prettymuchwhite;
        }
      }
    }

    .landing-first-impression {
      display: grid;
      background-color: black;

      img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: top center;
      }

      .first-impression-text-container {
        position: absolute;
        top: 25%;
        left: 33%;
        z-index: 1;
        color: white;

        .first-impression-subtext-container {
          display: flex;
          gap: 50px;
          font-family: $font-header;
          font-size: 1.5rem;
          font-weight: normal;
        }
      }
    }

    .landing-section {
      padding: 100px 200px;

      &.landing-flex {
        @extend %flex-row;
        gap: 50px;
        flex-wrap: wrap;
        justify-content: center;
      }

      &#save-your-story {
        background-color: $offwhite;
      }

      &.landing-side-by-side {
        @extend %flex-row;
        gap: 50px;
        justify-content: center;

        .side-by-side-image-container {
          flex: 2;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
            border-radius: 16px;
          }
        }

        .side-by-side-text-container {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 20px;

          .side-by-side-text-header {
            font-family: $font-header;
            font-size: 1.8rem;
            margin-bottom: 10px;
          }

          .side-by-side-text-subheader {
            font-family: $font-body-new;
            font-size: 0.8rem;
            font-weight: normal;
          }
        }
      }

      .landing-section-title {
        flex: 1 100%;
        font-family: $font-header;
        font-size: 2.5rem;
        text-align: center;
      }

      .landing-sub-section {
        max-width: 300px;

        .landing-sub-section-image {
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-position: top center;
          border-radius: 16px;
          margin-bottom: 20px;
        }

        .landing-sub-section-header {
          font-family: $font-header;
          font-size: 1.8rem;
          margin-bottom: 10px;
        }

        .landing-sub-section-subheader {
          font-family: $font-body-new;
          font-size: 0.8rem;
          font-weight: normal;
        }
      }
    }
  }
}
