@import '../../Styles/shared.scss';

.public-footer-outer {
  .public-footer-inner {
    @extend %flex-row;
    justify-content: space-between;
    padding: 50px 200px;
    background-color: $offblack;
    color: $prettymuchwhite;

    .public-footer-section {
      .public-footer-header {
        font-family: $font-header;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .public-footer-sub-section {
        @extend %flex-col;
        margin: 20px 0;

        .public-footer-sub-header {
          font-family: $font-header;
          font-size: 1.1rem;
          margin-bottom: 10px;
        }

        .public-footer-sub-text {
          font-family: $font-body-new;
          font-size: 0.8rem;

          + .public-footer-sub-text {
            margin-top: 3px;
          }
        }
      }
    }

    /*
        <div className='public-footer-section'>
          <div className='public-footer-header'>StoryLinq</div>

          <div className='public-footer-sub-section'>
            <div className='public-footer-sub-header'>About</div>
            <div className='public-footer-sub-text'>
              Copyright <br /> 2020 Ideas Unlimited Seminars, Inc. <br /> All rights reserved.
            </div>
          </div>
        </div>

        <div className='public-footer-section'>
          <div className='public-footer-sub-section'>
            <div className='public-footer-sub-header'>Terms</div>
            <a href='https://www.iubenda.com/privacy-policy/71341802' target='_blank' rel='noreferrer' className='public-footer-sub-text'>
              Privacy Policy
            </a>
            <a href='https://www.iubenda.com/terms-and-conditions/71341802' target='_blank' rel='noreferrer' className='public-footer-sub-text'>
              Terms of Service
            </a>
            <a href='/contact' className='public-footer-sub-text'>
              Contact Us
            </a>
          </div>

          <div className='public-footer-sub-section'>
            <div className='public-footer-sub-header'>Resources</div>
            <div className='public-footer-sub-text'>Blog</div>
            <div className='public-footer-sub-text'>Podcast</div>
          </div>
        </div>

        <div className='public-footer-section'>
          <div className='public-footer-sub-section'>
            <div className='public-footer-sub-header'>Join Us</div>
            <a href='/signup' className='public-footer-sub-text'>
              Sign Up
            </a>
            <a href='/login' className='public-footer-sub-text'>
              Login
            </a>
          </div>
        </div>

    */
  }
}
