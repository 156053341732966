@import '../../Styles/shared.scss';

.ae-signup-outer {
  min-height: 100vh;
  width: 100%;
  background-color: $offwhite;
  @extend %flex-col;
  justify-content: center;
  align-items: center;

  .ae-signup-inner {
    width: min(850px, 90%);
    display: grid;
    gap: 20px;
    padding: 100px 0;

    @include xs-down {
      padding: 30px 0;
    }

    @include md-down {
      display: flex;
      flex-direction: column;
    }

    > div {
      border-radius: 18px;
      box-shadow: $shadow-lighter;
      background-color: white;
      padding: 50px 60px;
      position: relative;

      @include sm-down {
        padding: 30px 40px;
      }

      @include xs-down {
        padding: 20px 30px;
      }
    }

    .welcome-container {
      grid-row-start: 1;
      grid-row-end: span 1;
      grid-column-start: 1;
      grid-column-end: span 1;
      @extend %flex-col;
      justify-content: center;
      text-align: center;

      .welcome-title {
        font-family: $font-header;
        font-size: 2rem;
        margin-bottom: 20px;
      }

      .welcome-subtitle {
        font-size: 0.9rem;
      }
    }

    .ae-logo-container {
      grid-row-start: 2;
      grid-row-end: span 1;
      grid-column-start: 1;
      grid-column-end: span 1;

      display: grid;
      place-content: center;

      img {
        height: 200px;
        width: 100%;
        object-fit: cover;
      }
    }

    .form-container {
      grid-row-start: 1;
      grid-row-end: span 2;
      grid-column-start: 2;
      grid-column-end: span 1;

      justify-content: center;
      @extend %flex-col;
      gap: 8px;

      .form-container-title {
        font-family: $font-header;
        font-size: 1.5rem;
        margin-bottom: 10px;
      }

      input {
        padding: 10px 20px;
        border-radius: 8px;
        border: $border-lightest;
        outline: none;
        font-size: 0.7rem;
        font-weight: normal;
        width: 100%;
      }

      .form-container-buttons {
        @extend %flex-col;
        gap: 10px;
        margin-top: 10px;

        button {
          @extend %btn-basic;
          @extend %label;
          background-color: $offwhite;
          color: $dark;
          font-size: 0.7rem;
          padding: 12px 20px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          outline: none;
          border: none;
          display: grid;
          place-items: center;

          + button {
            background-color: black;
            color: white;
          }
        }
      }

      .disclaimer {
        font-size: 0.65rem;
        color: $medium;
        margin-top: 20px;
        text-align: center;

        span {
          @extend %btn-basic;
          text-decoration: underline;
        }
      }
    }
  }
}
