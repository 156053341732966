@import '../../Styles/shared.scss';

.training-portal-training-outer {
  .training-portal-training-inner {
    padding: 20px;
    border: $border-lightest;
    border-radius: 16px;

    a {
      text-decoration: none;
      @extend %flex-col;
      gap: 15px;

      .training-portal-training-image {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: 10px;
      }

      .training-portal-training-title {
        font-family: $font-header;
        font-size: 1.4rem;
        font-weight: 700;
        text-decoration: underline;

        .training-portal-training-subtitle {
          font-family: $font-body-new;
          font-size: 0.7rem;
          font-weight: 400;
          color: $medium;
        }
      }

      .training-portal-training-description {
        font-family: $font-body-new;
        font-size: 0.75rem;
        color: medium;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  }
}
