@import '../../Styles/shared.scss';

.signup-modal-outer {
  width: 100%;

  .signup-modal-inner {
    width: 100%;

    .signup-modal-content {
      width: min(450px, 90%);
      position: relative;

      .signup-modal-header {
        font-family: $font-header;
        font-size: 2.4rem;
        margin-bottom: 20px;

        @include sm-down {
          font-size: 1.8rem;
        }
      }

      .signup-modal-subheader {
        font-family: $font-body-new;
        font-size: 0.7rem;
        color: $light;

        span {
          @extend %btn-basic;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .signup-modal-form {
        margin-top: 20px;
        width: min(450px, 90vw);
        overflow: hidden;

        .signup-modal-form-header {
          @extend %label;
          margin-top: 20px;
          margin-bottom: 5px;
          font-size: 0.7rem;
        }

        .signup-modal-select {
          margin-bottom: 10px;
          border-radius: 8px;
          border: $border-lightest;
          font-family: $font-body-new;
          font-size: 0.7rem;
          color: $dark;
          // width: 450px;
          width: 100%;
          border: none;
          outline: none;
        }

        input {
          + input {
            margin-top: 10px;
          }

          padding: 8px 12px;
          border-radius: 5px;
          border: $border-lightest;
          font-family: $font-body-new;
          font-size: 0.8rem;
          color: $medium;
          width: 100%;
        }
      }

      .signup-modal-footer {
        @extend %flex-col;
        margin-top: 20px;
        width: min(450px, 90vw);

        button {
          @extend %btn-basic;
          margin-top: 10px;
          padding: 8px 12px;
          border-radius: 5px;
          border: none;
          font-family: $font-body-new;
          font-size: 0.8rem;
          color: $medium;
          width: 100%;
          cursor: pointer;
          background-color: $primary;
          color: $prettymuchwhite;
          font-weight: bold;

          &.signup-button {
            background-color: $primary;
            color: white;
          }

          &.cancel-button {
            background-color: $offwhite;
            color: $offblack;
          }
        }
      }
    }
  }
}
