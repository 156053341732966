#logo {
  animation: fill_all 5s ease;
  fill: white;
  height: 9rem;
}

@keyframes fill_all {
  from {
    fill: transparent;
  }
  80% {
    fill: transparent;
  }
  to {
    fill: white;
  }
}

#logo path {
  stroke-dashoffset: 0;
  stroke-dasharray: 100%;
  animation: dash 5s ease;
}

#logo path:nth-child(2) {
  animation-delay: -0.75s;
}

#logo path:nth-child(3) {
  animation-delay: -0.5s;
}

#logo path:nth-child(4) {
  animation-delay: -0.25s;
}

#logo path:nth-child(5) {
  animation-delay: -1s;
}

#logo path:nth-child(6) {
  animation-delay: -2s;
}

#logo path:nth-child(7) {
  animation-delay: -1.5s;
}

#logo path:nth-child(8) {
  animation-delay: -1.75s;
}

#logo path:nth-child(9) {
  animation-delay: -1.25s;
}

@keyframes dash {
  0%,
  50% {
    stroke-dashoffset: 100%;
  }
  to {
    stroke-dashoffset: 0;
  }
}

#learn,
#build,
#present {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;

  margin-right: 2rem;
}

#learn {
  animation: uppe 0.8s ease;
}

#build {
  animation: uppe2 1.6s ease;
}

#present {
  animation: uppe3 2.4s ease;
}

@keyframes uppe {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes uppe2 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes uppe3 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  66% {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
