@import '../../Styles/shared.scss';

.podcast-magic-outer {
  .podcast-magic-inner {
    .podcast-magic-title {
      font-family: $font-header;
      font-size: 3rem;
      font-weight: 700;
    }

    .podcast-magic-subtitle {
      max-width: 600px;
      font-family: $font-body-new;
      font-size: 0.8rem;
      font-weight: normal;
      color: $medium;

      span {
        @extend %btn-basic;
        text-decoration: underline;
        font-weight: bold;
      }
    }

    .tab-container {
      @extend %label;
      @extend %flex-row;
      gap: 20px;
      height: 100%;
      border-radius: 0;
      font-size: 12px;
      color: $darkest;
      border-bottom: 4px solid transparent;
      transition: all 0.2s ease-in-out;
      font-weight: bold;
      margin: 20px 0;

      .tab {
        &.active {
          border-bottom: 4px solid $primary;
          color: $darkest;
        }

        &.active > span {
          background-color: $prettymuchwhite;
        }

        span {
          @extend %flex-row;
          justify-content: center;
          padding: 8px 12px;
          border-radius: 4px;
          margin-bottom: 8px;
          cursor: pointer;
          border: $border-lightest;
          transition: all 0.2s ease-in-out;
          text-align: center;

          &:hover {
            border: $border-dark;
          }
        }
      }
    }

    .podcast-magic-training {
      width: 100%;
      background-color: white;
      overflow: hidden;

      iframe {
        width: 100%;
        aspect-ratio: 16/9;
        outline: none;
        border: none;
        border: $border-lightest;
        border-radius: 16px;
        overflow: hidden;
      }
    }
  }
}
