@import '../Styles/shared.scss';

.main-container-outer {
  .open-help-modal-button {
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 50px;
    background-color: $offblack;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    transition: transform 0.2s ease-out;
    z-index: 999;

    &:hover {
      transform: scale(1.07);
    }

    svg {
      color: white;
      height: 30px;
      width: 30px;
    }
  }

  .storylinq-toasts {
    font-family: $font-body-new;
    font-size: 0.7rem;
    padding: 10px 16px;
  }

  .main-container-inner {
    @extend %flex-row;

    @include md-down {
      flex-direction: column;
    }

    .navbar-container {
      width: 250px;
      height: 100vh;

      @include md-down {
        display: none;
      }

      position: fixed;
      top: 0;
      left: 0;
    }

    .content-container {
      @extend %flex-col;
      flex: 1;
      margin-left: 250px;
      padding: 100px 50px 50px 100px;

      @include md-down {
        margin-left: 0;
        padding: 30px;
        padding-top: 50px;
        padding-bottom: 100px;
      }

      @include xl-down {
        // margin-left: 0;
        padding: 30px;
        padding-top: 50px;
        padding-bottom: 100px;
      }
    }
  }
}
