@import '../../Styles/shared.scss';

.upgrade-modal-outer {
  .upgrade-modal-inner {
    .upgrade-modal-header {
      .upgrade-modal-title {
        font-family: $font-header;
        font-size: 2rem;
        font-weight: 700;
      }

      .upgrade-modal-subtitle {
        font-family: $font-body-new;
        font-size: 0.7rem;
        color: $medium;
      }
    }

    .upgrade-modal-body {
      @extend %flex-col;
      gap: 20px;
      margin: 20px 0;

      .upgrade-outer {
        cursor: pointer;
        transition: border 0.2s ease-in-out;

        &.active {
          .upgrade-inner {
            border: $border-primary;
          }
        }

        .upgrade-inner {
          border: $border-lightest;
          border-radius: 16px;
          padding: 20px;
          @extend %flex-row;
          justify-content: space-between;
          gap: 20px;

          .upgrade-text-container {
            flex: 3;

            .upgrade-title {
              font-family: $font-header;
              font-size: 1.3rem;
              font-weight: 700;
              margin-bottom: 5px;
            }

            .upgrade-description {
              font-family: $font-body-new;
              font-size: 0.7rem;
              color: $medium;
            }
          }

          .upgrade-price-container {
            flex: 1;
            @extend %flex-col;
            align-items: flex-end;
            justify-content: center;

            .upgrade-price {
              font-family: $font-header;
              font-size: 1.5rem;
              font-weight: 700;
            }

            .disclaimer {
              font-family: $font-body-new;
              font-size: 0.7rem;
              color: $medium;
            }
          }
        }
      }
    }

    .upgrade-modal-footer {
      @extend %flex-row;
      justify-content: flex-end;
      gap: 10px;
      position: absolute;
      bottom: 40px;
      right: 40px;

      button {
        @extend %btn-basic;
        @extend %label;
        width: max-content;
        padding: 10px 16px;
        color: white;
        background-color: black;
        border: none;
        outline: none;
        border-radius: 6px;
        font-size: 0.7rem;
        font-weight: normal;

        &.upgrade-modal-cancel-button {
          background-color: $offwhite;
          color: black;
        }
      }
    }

    .upgrade-modal-prompt-window {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-color: white;

      .upgrade-modal-prompt-window-inner {
        padding: 50px 60px;
      }
    }

    .upgrade-modal-success-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 101;

      background-color: $success;
      display: grid;
      place-content: center;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-out;

      &.active {
        opacity: 1;
        pointer-events: all;
      }

      .upgrade-modal-success-overlay-inner {
        text-align: center;
        @extend %flex-col;
        gap: 20px;
        width: min(80%, 500px);
        margin: 0 auto;

        .upgrade-modal-success-overlay-title {
          font-family: $font-header;
          font-size: 1.5rem;
          font-weight: 700;
          color: white;
        }

        .upgrade-modal-success-overlay-subtitle {
          font-family: $font-body-new;
          font-size: 0.8rem;
          color: white;
        }

        .upgrade-modal-success-overlay-button {
          @extend %btn-basic;
          @extend %label;
          width: max-content;
          padding: 10px 16px;
          color: white;
          background-color: black;
          border: none;
          outline: none;
          border-radius: 6px;
          font-size: 0.7rem;
          font-weight: normal;
          margin: 0 auto;
        }
      }

      .animated-check-mark {
        margin: 0 auto;
        position: relative;

        .checkmark {
          position: absolute;
          height: 20px;
          width: 20px;
          color: $success;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -45%);
          z-index: 2;
        }

        .spiky-circle {
          margin: 0 auto;
          position: relative;
          width: 50px;
          height: 50px;
          background-color: #3498db; /* Circle color */
          border-radius: 50%;
        }

        /* Create spikes using ::before and ::after pseudo-elements */
        .spiky-circle::before,
        .spiky-circle::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: white; /* Spike color */
          /* Using clip-path to create the spikes */
          border-radius: 20%;
          z-index: 1;
        }

        .spiky-circle::after {
          animation: 5s rotate infinite linear;
        }

        .spiky-circle::before {
          animation: 5s rotate-askew infinite linear;
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotate-askew {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(135deg);
  }
}
