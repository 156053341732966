@import '../../Styles/shared.scss';

.training-portal-outer {
  .training-portal-inner {
    .training-portal-title {
      font-family: $font-header;
      font-size: 3rem;
      font-weight: 700;
    }

    .trainings-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 30px;
    }
  }
}
