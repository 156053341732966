$font-size-xxxl: 38px;
$font-size-xxl: 32px;
$font-size-xl: 24px;
$font-size-l: 18px;
$font-size-ml: 16px;
$font-size-m: 14px;
$font-size-s: 12px;
$font-size-xs: 8px;

// New fonts - after July 2022
$font-header: 'Vidaloka', serif;
$font-social: 'mediasans-bold', serif;
$font-body-new: 'DM Sans', 'Karla', sans-serif;
