@import '../../Styles/shared.scss';

.add-content-modal-outer {
  .add-content-modal-inner {
    .add-content-modal-content {
      padding: 20px;

      .add-content-modal-header {
        font-family: $font-header;
        font-size: 2rem;
        font-weight: 700;
      }

      .add-content-modal-subheader {
        font-family: $font-body-new;
        font-size: 0.7rem;
        color: $medium;
      }

      .add-content-modal-form {
        margin: 20px 0;

        input {
          width: 400px;
          padding: 6px 10px;
          border-radius: 5px;
          font-family: $font-body-new;
          font-size: 0.8rem;
          color: dark;
          border: $border-light;
          outline: none;
        }
      }

      .add-content-modal-actions {
        @extend %flex-row;
        justify-content: flex-end;
        gap: 10px;

        button {
          @extend %btn-basic;
          @extend %label;
          width: max-content;
          padding: 10px 16px;
          color: white;
          background-color: black;
          border: none;
          outline: none;
          border-radius: 6px;
          font-size: 0.7rem;

          &.add-content-button {
            background-color: $primary;
            color: white;
          }
        }
      }
    }
  }
}
