@import '../../Styles/shared.scss';

.navbar-outer {
  @extend %flex-col;
  justify-content: center;
  background-color: $darker;
  color: white;

  @include md-down {
    background-color: white;
    color: black;
    display: none;
  }

  .navbar-inner {
    @extend %flex-col;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 100vh;
    gap: 50px;
    padding: 20px;

    .open-nav-button {
      @extend %btn-basic;
      position: fixed;
      top: 20px;
      left: 20px;
      z-index: 100;
      background-color: $darker;
      color: white;
      border: $border-lightest;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: none;

      @include sm-down {
        display: grid;
        place-content: center;
      }

      svg {
        height: 30px;
        width: 30px;
      }
    }

    .navbar-section {
      @extend %flex-col;
      width: 100%;
      gap: 10px;

      .navbar-header {
        font-family: 'Karla';
        font-size: 2rem;
        font-weight: 700;
      }

      .navbar-link {
        opacity: 0.5;
        font-family: $font-body-new;
        font-size: 1rem;
        font-weight: 700;
        transition: opacity 0.3s ease-out;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        &.active {
          opacity: 1;
          color: $primary;
        }
      }
    }
  }
}
