@import '../../Styles/shared.scss';

.confirm-prompt-outer-container {
  font-family: $font-body-new;
  .header {
    margin: 0 0 8px;
    line-height: 1;
  }
  .subheader {
    font-size: 13px;
    margin: 0 0 8px;
  }

  textarea,
  input {
    @extend %input-shared;
    margin-top: 12px;
    width: 100%;
    font-family: $font-body-new;
    font-size: 14px;
  }

  .disclaimer {
    font-size: 0.75rem;
    color: $light;
    margin-top: 8px;
  }

  .react-confirm-alert-button-group {
    @extend %flex-row;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;

    button {
      @extend %btn-basic;
      @extend %label;
      background-color: black;
      color: white;
      font-size: 0.7rem;
      padding: 10px 16px;
      border-radius: 5px;
      outline: none;
      border: none;

      &.cancel-btn {
        background: $offwhite;
        color: $dark;
      }
    }
  }
}
