%btn-shared {
  @extend %flex-center;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  font-family: $font-body-new;
  white-space: nowrap;
  letter-spacing: 0.7px;
  -webkit-appearance: none;
  border: 1px solid transparent;

  &:hover {
    opacity: 0.85;
  }
  &:focus {
    opacity: 0.95;
  }
}
%btn-small {
  font-size: 12px;
  padding: 4px 8px;
}

%btn-new {
  @extend %label;
  padding: 6px 8px;
}

%btn-label {
  @extend %btn-basic;
  @extend %label;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 10px;
}

%btn-inactive {
  cursor: default;
  &:hover {
    opacity: 1;
  }
}

%btn-basic {
  cursor: pointer;
  border-radius: 4px;
  white-space: nowrap;
  -webkit-appearance: none;
  &:hover {
    opacity: 0.8;
  }
}

%btn-dark {
  @extend %btn-shared;
  background-color: $dark;
  border-color: $dark;
  color: $white;
}

%btn-dark-new {
  @extend %btn-dark;
  @extend %btn-new;
}

%btn-darker {
  @extend %btn-shared;
  background-color: $darker;
  border-color: $darker;
  color: $white;
}
%btn-darker-new {
  @extend %btn-darker;
  @extend %btn-new;
}

%btn-darkest {
  @extend %btn-shared;
  background-color: $darkest;
  border-color: $darkest;
  color: $white;
}
%btn-darkest-new {
  @extend %btn-darkest;
  @extend %btn-new;
}

%btn-offwhite {
  @extend %btn-shared;
  background-color: $offwhite;
  border-color: $offwhite;
  color: $dark;
}
%btn-offwhite-new {
  @extend %btn-offwhite;
  @extend %btn-new;
}

%btn-prettymuchwhite {
  @extend %btn-shared;
  background-color: $prettymuchwhite;
  border-color: $prettymuchwhite;
  color: $dark;
}
%btn-prettymuchwhite-new {
  @extend %btn-prettymuchwhite;
  @extend %btn-new;
}

%btn-light {
  @extend %btn-shared;
  background-color: $medium;
  border-color: $medium;
  color: $white;
}
%btn-light-new {
  @extend %btn-light;
  @extend %btn-new;
}

%btn-medium {
  @extend %btn-shared;
  background-color: $medium;
  border-color: $medium;
  color: $white;
}
%btn-medium-new {
  @extend %btn-medium;
  @extend %btn-new;
}

%btn-lighter {
  @extend %btn-shared;
  background-color: $lighter;
  border-color: $lighter;
  color: $dark;
}
%btn-lighter-new {
  @extend %btn-lighter;
  @extend %btn-new;
}

%btn-lightest {
  @extend %btn-shared;
  background-color: $lightest;
  border-color: $lightest;
  color: $dark;
}
%btn-lightest-new {
  @extend %btn-lightest;
  @extend %btn-new;
}

%btn-white {
  @extend %btn-shared;
  background-color: $white;
  border-color: $white;
  color: $dark;
}

%btn-white-new {
  @extend %btn-white;
  @extend %btn-new;
}

%btn-disabled {
  @extend %btn-offwhite;
  color: $light;
  cursor: default;

  &:hover {
    opacity: 1;
  }
}

%btn-primary {
  @extend %btn-shared;
  background-color: $primary;
  border-color: $primary;
  color: $white;
}
%btn-primary-new {
  @extend %btn-primary;
  @extend %btn-new;
}

%btn-text {
  @extend %btn-shared;
  background-color: transparent;
  border-color: transparent;
  color: $dark;
}

%btn-text-new {
  @extend %btn-text;
  @extend %btn-new;
}

%btn-text-underline {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

%btn-primary-outline {
  @extend %btn-shared;
  background-color: $white;
  border: $border-primary;
  color: $primary;
}

%btn-outline {
  @extend %btn-shared;
  background-color: $white;
  border: $border-dark;
  color: $dark;
}

%btn-outline-new {
  @extend %btn-outline;
  @extend %btn-new;
}

%btn-dark-outline {
  @extend %btn-shared;
  background-color: $offwhite;
  border: $border-dark;
  color: $dark;
}

%btn-light-outline {
  @extend %btn-shared;
  background-color: $offwhite;
  border: $border-light;
  color: $dark;
}

%btn-white-outline {
  @extend %btn-shared;
  background-color: transparent;
  border: $border-white;
  color: $white;
}

%btn-inverted {
  @extend %btn-shared;
  background-color: $white;
  color: $darker;
  border: $border-darker;
}

%btn-inverted-new {
  @extend %btn-inverted;
  @extend %btn-new;
}

%clickable {
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
}

%clickable-subtle {
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
}
