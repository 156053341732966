:root {
  --background-color: #1f1f1f;
  --background-color-pro: #333333;
}

$primary: #bd1e2d; // Emerald Green
$primaryDark: #186042; // Forest (Darker) Green
$primarySMS: #333;
$primaryPro: var(--background-color-pro);
$secondary: #c6e9e4;
$secondary-dark: #5ad4bd;
$secondary-md: #9bd7ce;
$tertiary: #f7b50045; // faded yellow
$tertiary-light: #f4e1fb; // lighter faded pink
$lightpurple: #d0c9e7;
$lighterpurple: rgba(208, 201, 231, 0.4);
$gold: #ffd300;
$white: white;
$whynotusewhite: #fcfcfc;
$prettymuchwhite: #f7f7f7;
$offwhite: #f2f2f2;
$lightest: #e5e5e5;
$lighter: #cccccc;
$light: #9c9a9c;
$medium: #767676;
$offblack: #212121;
$dark: #4a4a4a;
$darker: #333;
$darkest: #1f1f1f;
$prettymuchblack: #141414;
$whynotuseblack: #040404;
$black: #000;
$error: #d30000;
$yield: #eed20288;
$success: #96b603;
$fb-blue: #4267b2;
$twitter-blue: #1da1f2;
$link-blue: #4267b2;
$success: #11835a;
