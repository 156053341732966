@import '../../Styles/shared.scss';

.collapsed-navbar-button {
  @extend %btn-basic;
  background-color: $darker;
  color: white;
  border: $border-lightest;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;

  @include md-down {
    display: grid;
    place-content: center;
  }

  svg {
    height: 15px;
    width: 15px;
  }

  .collapsed-navbar-button-inner {
    @extend %flex-col;

    .collapsed-navbar-button-line {
      height: 1px;
      width: 12px;
      background-color: white;
      margin: 2px 0;
    }
  }
}

.collapsed-navbar-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background-color: white;

  @include md-down {
    background-color: white;
    color: black;
    display: block;
  }

  .collapsed-navbar-inner {
    @extend %flex-col;
    gap: 30px;
    width: 80%;
    height: 100vh;
    padding: 50px;

    .collapsed-navbar-section {
      @extend %flex-col;
      width: 100%;
      gap: 10px;

      .collapsed-navbar-header {
        font-family: $font-header;
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .collapsed-navbar-link {
        opacity: 0.5;
        font-family: $font-body-new;
        font-size: 1rem;
        font-weight: 700;
        transition: opacity 0.3s ease-out;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        &.active {
          opacity: 1;
          color: $primary;
        }
      }
    }
  }
}
