@import '../../Styles/shared.scss';

.download-content-modal-outer {
  .download-content-modal-inner {
    .download-content-modal-content {
      .download-content-modal-header {
        font-family: $font-header;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .download-content-modal-subheader {
        font-size: 0.8rem;
        margin-bottom: 20px;
        color: $medium;
      }

      .download-content-modal-form {
        @extend %flex-col;
        gap: 20px;

        .download-content-modal-form-training {
          @extend %flex-col;
          gap: 3px;

          .download-content-modal-form-training-title {
            @extend %label;
            font-size: 0.8rem;
            font-weight: 700;
          }

          .download-content-modal-form-training-select {
            width: 100%;
            // z-index: 20;
            font-size: 0.7rem;

            .react-select__menu {
              z-index: 1000; /* or any other high value */
            }
          }
        }
      }

      .download-content-modal-actions {
        position: sticky;
        bottom: 0;
        width: 100%;
        padding: 20px 0;
        background-color: white;
        border-top: $border-lightest;
        @extend %flex-row;
        justify-content: flex-end;
        gap: 10px;
        transform: translateY(30px);

        .download-content-modal-actions-button {
          @extend %label;
          @extend %btn-basic;
          font-size: 0.8rem;
          padding: 10px 20px;
          border-radius: 8px;
          background-color: white;
          color: black;
          font-weight: 700;
          border: $border-dark;

          &.download-button {
            background-color: black;
            color: white;
          }
        }
      }
    }
  }
}
