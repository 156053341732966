@import '../../Styles/shared.scss';

.training-portal-controls-outer {
  .training-portal-controls-inner {
    @extend %flex-row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 2rem;

    .tab-container {
      @extend %label;
      @extend %flex-row;
      gap: 20px;
      height: 100%;
      border-radius: 0;
      font-size: 12px;
      color: $darkest;
      border-bottom: 4px solid transparent;
      transition: all 0.2s ease-in-out;
      font-weight: bold;

      .tab {
        &.active {
          border-bottom: 4px solid $primary;
          color: $darkest;
        }

        &.active > span {
          background-color: $prettymuchwhite;
        }

        span {
          @extend %flex-row;
          justify-content: center;
          padding: 8px 12px;
          border-radius: 4px;
          margin-bottom: 8px;
          cursor: pointer;
          border: $border-lightest;
          transition: all 0.2s ease-in-out;
          text-align: center;

          &:hover {
            border: $border-dark;
          }
        }
      }
    }

    .search-container {
      @extend %flex-row;
      align-items: center;
      height: 100%;
      border: $border-lightest;
      border-radius: 500px;
      padding: 10px 15px;

      input {
        border: none;
        outline: none;
        font-family: $font-body-new;
        font-size: 0.8rem;
      }
    }
  }
}
