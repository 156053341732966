@import '../../Styles/shared.scss';

.presentation-builder-outer {
  .presentation-builder-inner {
    .presentation-builder-header {
      margin-bottom: 50px;

      .presentation-builder-title {
        font-family: $font-header;
        font-size: 3rem;
        font-weight: 700;
      }

      .presentation-builder-subtitle {
        font-family: $font-body-new;
        font-size: 0.8rem;
        color: medium;

        .presentation-builder-subtitle-download {
          @extend %btn-basic;
          @extend %label;
          padding: 8px 16px;
          background-color: black;
          color: white;
          font-size: 0.7rem;
          width: max-content;
          margin-top: 1rem;
        }

        span {
          @extend %btn-basic;
          text-decoration: underline;
        }
      }
    }

    .presentation-builder-trainings-container {
      @extend %flex-col;
      gap: 50px;
    }
  }
}
