@import '../../Styles/shared.scss';

.loader-outer-container {
  @extend %flex-col-center;
  position: relative;
  width: 100%;
  pointer-events: none;

  &.has-message {
    margin-top: -12px;
  }

  .loader-message {
    position: absolute;
    bottom: 32px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: $dark;
    font-family: $font-body-new;
  }
}
