@import '../../Styles/shared.scss';

.account-modal-outer {
  .account-modal-inner {
    .account-modal-content {
      background-color: $offwhite;
      border-radius: 16px;
      padding-top: 30px;
      // padding-bottom: 30px;

      .account-modal-header {
        font-family: $font-header;
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 10px;
        padding: 0px 30px;
      }

      .account-modal-body {
        @extend %flex-col;
        gap: 40px;
        padding: 0px 30px;

        .body-subsection {
          .body-subsection-label {
            @extend %label;
            font-size: 0.75rem;
            margin-bottom: 5px;
          }

          .body-subsection-sublabel {
            font-size: 0.7rem;
            color: $medium;
            margin-bottom: 5px;
          }

          .account-modal-select {
            width: 100%;
            font-size: 0.7rem;
            margin-top: 1rem;
          }
        }

        .account-modal-body-section {
          @extend %flex-col;
          gap: 10px;
          padding: 20px;
          background-color: white;
          border-radius: 5px;
          box-shadow: $shadow-lighter;

          &:last-child {
            margin-bottom: 100px;
          }

          .account-modal-body-section-subtitle {
            font-size: 0.7rem;
            color: $medium;
          }

          .input-container {
            .input-container-label {
              @extend %label;
              font-size: 0.75rem;
              margin-bottom: 5px;
            }

            .input-container-sublabel {
              font-size: 0.7rem;
              color: $medium;
              margin-bottom: 5px;
            }

            input {
              width: 100%;
              padding: 8px 12px;
              border-radius: 5px;
              border: $border-lightest;
              outline: none;
              transition: border 0.2s ease-out;

              &:focus {
                border: $border-lighter;
              }
            }
          }
        }
      }

      .account-modal-footer {
        @extend %flex-row;
        gap: 20px;
        justify-content: flex-end;
        padding: 20px;
        border-top: $border-lightest;
        background-color: $white;
        position: sticky;
        bottom: 0;
        z-index: 2;

        &.active {
          button {
            cursor: pointer;
            filter: none;
            pointer-events: all;
          }
        }

        button {
          @extend %btn-basic;
          @extend %label;
          background-color: $offwhite;
          color: $dark;
          font-size: 0.7rem;
          padding: 12px 20px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          outline: none;
          border: none;
          display: grid;
          place-items: center;
          cursor: not-allowed;
          filter: brightness(0.5) grayscale(1);
          pointer-events: none;
          transition: all 0.2s ease-out;

          + button {
            background-color: $success;
            color: white;
          }
        }
      }
    }
  }
}
