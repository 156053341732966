@import '../../Styles/shared.scss';

.dashboard-outer {
  .dashboard-inner {
    @extend %flex-col;
    gap: 50px;

    .dashboard-section {
      + .dashboard-section {
        padding-top: 50px;
        border-top: $border-lightest;
      }

      .dashboard-title {
        font-family: $font-header;
        font-size: 3rem;
        font-weight: 700;
      }

      .dashboard-subtitle {
        font-family: $font-body-new;
        font-size: 0.8rem;
        color: medium;
      }

      .dashboard-section-title {
        font-family: $font-header;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .live-training-container {
        @extend %flex-col;
        gap: 30px;

        .live-training {
          padding: 20px;
          border: $border-lightest;
          border-radius: 16px;
          background-color: white;
          overflow: hidden;
          @extend %flex-row;
          gap: 30px;

          @include sm-down {
            flex-direction: column;
          }

          img {
            height: 250px;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 10px;
          }

          .live-training-text-container {
            @extend %flex-col;
            justify-content: center;

            .live-training-title {
              font-family: $font-header;
              font-size: 1rem;
              font-weight: 700;
              margin-bottom: 5px;

              .live-training-date {
                font-family: $font-body-new;
                font-size: 0.7rem;
                font-weight: normal;
                color: $medium;
              }
            }

            .live-training-description {
              font-family: $font-body-new;
              font-size: 0.8rem;
              margin: 1rem 0;

              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
            }

            a {
              text-decoration: none;

              .live-training-button {
                @extend %btn-basic;
                width: 100%;
                background-color: $primary;
                color: white;
                border-radius: 5px;
                padding: 10px;
                font-family: $font-body-new;
                font-size: 0.7rem;
                text-align: center;
                margin-top: 20px;
              }
            }
          }
        }
      }

      .dashboard-section-card-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
        gap: 30px;

        @include xs-down {
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }

        .dashboard-section-card {
          aspect-ratio: 16/9;
          background-color: $darker;
          color: white;
          border-radius: 10px;
          cursor: pointer;
          position: relative;
          overflow: hidden;

          @include xs-down {
            aspect-ratio: 1;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
          }

          .image-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            transition: 0.3s ease-out background;
            padding: 20px;
            @extend %flex-col;
            justify-content: flex-end;

            &:hover {
              background-color: rgba(0, 0, 0, 0.3);
            }

            .dashboard-section-card-title {
              position: relative;
              z-index: 1;
              font-family: $font-header;
              font-size: 1.2rem;
              margin-bottom: 10px;
            }

            .dashboard-section-card-subtitle {
              position: relative;
              z-index: 1;
              font-family: $font-body-new;
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
}
